export const pyramidItems = [
    [{ label: '10', color: '#808080' }],
    [{ label: '8', color: '#808080' }, { label: '9', color: '#808080' }],
    [{ label: '7', color: '#808080' }, { label: '6', color: '#808080' }, { label: '5', color: '#808080' }],
    [{ label: '1', color: '#808080' }, { label: '2', color: '#808080' }, { label: '3', color: '#808080' }, { label: '4', color: '#808080' }]
];

export const pyramidStations = [
    {
        stationNameEng: 'Skill Station',
        stationNameVie: 'Trạm Kĩ Năng',
        price: 300000,
        testDetail: ['4 câu hỏi trắc nghiệm'],
        color: '#808080'
    },
    {
        stationNameEng: 'Mindset Station',
        stationNameVie: 'Trạm Tư Duy',
        price: 300000,
        testDetail: ['6 câu hỏi trắc nghiệm', '45p Zoom trực tiếp cùng chuyên gia'],
        color: '#808080'
    },
    {
        stationNameEng: 'Finish Station',
        stationNameVie: 'Trạm Về Đích',
        price: 300000,
        testDetail: ['20 câu hỏi trắc nghiệm', 'Mở khóa núi Kinabalu'],
        color: '#808080'
    },
]