import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StationDisplay = ({ icon, updatedColor, width, height, topIcon, leftIcon, topLine, leftLine, size = '2x' }) => {
    const defaultColor = '#808080';
    const hoverColor = '#FF0000';
    const [currentColor, setCurrentColor] = useState(defaultColor);

    useEffect(() => {
        if (updatedColor) {
            setCurrentColor(updatedColor);
        } else {
            setCurrentColor(defaultColor);
        }
    }, [updatedColor]);

    const lineStyle = {
        position: 'absolute',
        top: topLine || '50%',
        left: leftLine || '50%',
        width: width || '2px',
        height: height || '50px',
        backgroundColor: currentColor,
        zIndex: 21,
    };

    const iconStyle = {
        position: 'absolute',
        top: topIcon || '0',
        left: leftIcon || '0',
        color: currentColor,
        zIndex: 20,
        cursor: 'pointer',
    };

    return (
        <div
            onMouseEnter={() => setCurrentColor(hoverColor)}
            onMouseLeave={() => setCurrentColor(updatedColor || defaultColor)}
        >
            <div style={lineStyle} className="line"></div>
            <FontAwesomeIcon
                icon={icon}
                size={size}
                style={iconStyle}
                className="icon"
            />
        </div>
    );
};

export default StationDisplay;
