import React, { useEffect, useState } from 'react';
import "./styles.scss";
import { isEmpty } from 'lodash';
import Loading from "share/loading";
import FormatNumberic from "share/formatNumber";
import StationDisplay from 'app/modules/CourseOnlineModules/courseOnlineDetails/components/stationDisplay/stationDisplay';
import { faTents, faFlag } from '@fortawesome/free-solid-svg-icons';
import { pyramidStations } from './const';

const PyramidDisplay = ({ data, onPackClick, stationData, popupVisible }) => {
    const [pyramidData, setPyramidData] = useState("");
    const [station, setStation] = useState([]);
    const [popupVisibility, setpopupVisibility] = useState([false, false, false]);
    const [popupContent, setPopupContent] = useState(pyramidStations);
    const [isHover, setIsHover] = useState(false);
    const popupPositions = [
        { top: '77%', left: '80%' },
        { top: '53%', left: '6%' },
        { top: '-14%', left: '27%' },
    ];

    useEffect(() => {
        setPyramidData(data);
    }, [data]);

    useEffect(() => {
        setStation(stationData);
    }, [stationData]);

    useEffect(() => {
        setpopupVisibility(popupVisible);
    }, [popupVisible]);

    const handleContainerClick = (pack) => {
        onPackClick(pack);
    }

    const renderPrice = (price) => {
        return (
            <div className="headline-h4 course-offline-promo-price">
                <FormatNumberic num={isHover ? price : 0} />
            </div>
        );
    };

    const handleMouseEnter = (index) => {
        const updatedVisibility = [...popupVisibility];
        updatedVisibility[index] = true;
        setpopupVisibility(updatedVisibility);
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setpopupVisibility([false, false, false]);
        setIsHover(false);
    };
    return (!isEmpty(pyramidData) && !isEmpty(station)) ? (
        <div>
            <div>
                {station.map((s, index) => (
                    <div key={index} className='k'
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                    >
                        <StationDisplay
                            icon={index === 2 ? faFlag : faTents}
                            updatedColor={s.color}
                            topIcon={index === 0 ? "63%" : (index === 1 ? "39%" : "-8%")}
                            leftIcon={index === 0 ? "89%" : (index === 1 ? "15%" : "50%")}
                            width={index === 0 ? "100px" : (index === 1 ? "80px" : "0px")}
                            height={index === 0 ? "3px" : (index === 1 ? "3px" : "0px")}
                            leftLine={index === 0 ? "83%" : (index === 1 ? "17%" : "0px")}
                            topLine={index === 0 ? "76%" : (index === 1 ? "52%" : "0px")}
                            size="4x"
                        />
                        {popupVisibility[index] && (
                            <div
                                className={`popup-station ${popupVisibility[index] ? 'show' : ''}`}
                                style={{ top: popupPositions[index].top, left: popupPositions[index].left }}
                            >
                                <div style={{ fontWeight: "bolder" }}>{popupContent[index].stationNameEng}</div>
                                {popupContent[index].stationNameVie}
                                {popupContent[index].price && (
                                    <div className="d-flex align-items-center justify-content-center m-2">
                                        {renderPrice(popupContent[index].price)}
                                    </div>
                                )}
                                {popupContent[index].testDetail && popupContent[index].testDetail.map((detail, detailIndex) => (
                                    <div className='text-start' key={detailIndex} style={{ fontSize: '0.8em' }}>- {detail}</div>
                                ))}
                            </div>
                        )}
                    </div>

                ))}
            </div>
            <div className="wrapper mt-4">
                {
                    pyramidData.map((floor, floorIndex) => (
                        <React.Fragment key={floorIndex}>
                            <div className="layer">
                                {
                                    floor.map((pack, packIndex) => (
                                        <div
                                            key={packIndex}
                                            className="pack"
                                            style={{
                                                backgroundColor: pack.color,
                                                textAlign: 'center',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div
                                                className='container'
                                                style={{ width: '100%', height: '100%' }}
                                                onClick={() => handleContainerClick(pack)}
                                            >
                                                <p className='m-0' style={{ margin: 0, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    {pack.label}
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </React.Fragment>
                    ))
                }
            </div>
        </div>
    ) : (
        <Loading />
    );
}

export default PyramidDisplay;