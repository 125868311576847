import React, { useEffect, useState } from 'react';
import "./styles.scss";
import { isEmpty } from 'lodash';
import Loading from "share/loading";
import { mountainOverview } from './const';
import ScrollParallax from '../../components/scrollParallax';
import Mount from "assets/images/course/course-online/mount.jpg";
const MountainOverview = () => {

    const [hoveredItem, setHoveredItem] = useState(null);
    const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
    const handleMouseMove = (e) => {
        setPopupPosition({ top: e.clientY + 10 + 1200, left: e.clientX + 10 }); // Adjust to position the popup
    };
    const handlePopupClick = () => {
        if (hoveredItem) {
            console.log("item :", hoveredItem);
        }
    };

    const PopupCard = ({ item, onClose, style }) => {
        return (
            <div className="popup-card" onClick={onClose} style={style}>
                <div className="popup-content" style={{ backgroundImage: `url(${item.backgroundImage})` }}>
                    <h2>{item.mountainName}</h2>
                    <p>{item.mountainName}</p>
                    <p>Nhấp Vào Núi Để Xem Thêm</p>
                </div>
            </div>
        );
    };

    const renderPopup = hoveredItem && (
        <PopupCard
            item={hoveredItem}
            onClose={handlePopupClick}
            style={{
                position: 'absolute',
                top: popupPosition.top,
                left: popupPosition.left,
            }}
        />
    );
    return (!isEmpty(mountainOverview)) ? (
        <div style={{
            background: `url(${Mount})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
            height: '100%',
        }}>
            <ScrollParallax />
            <div className='pt-20'>
                <div className="container-temp">
                    {mountainOverview.map((item) => (
                        <div
                            key={item.id}
                            className="square"
                            style={{
                                width: item.mountainImageWidthDesktop,
                                height: item.mountainImageWidthDesktop,
                                background: (hoveredItem === item) ? 'blue' : 'grey',
                            }}
                            onMouseEnter={() => {
                                setHoveredItem(item);
                            }}
                            onMouseLeave={() => {
                                setHoveredItem(null);
                            }}
                            onMouseMove={handleMouseMove}
                        />
                    ))}
                    {renderPopup}
                </div>
            </div >
        </div>

    ) : (
        <Loading />
    );
}

export default MountainOverview;