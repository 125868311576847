import React, { useEffect, useState } from "react";
import SoulRetreatsBanner from "app/components/banner";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Scrollbar, Navigation } from "swiper";
import CourseItemOffline from "./components/courseItem";
import useSiteTitle from "core/hooks/useSiteTitle";

import banner from "assets/images/source/banner/khoa-hoc-offline-banner.jpg";
import "./styles.scss";
// import Loading from "share/loading";

// install Swiper modules
import background01 from "assets/images/course/course-offline/background01.png";
import background02 from "assets/images/course/course-offline/background02.png";
import mascotDragon from "assets/images/course/course-offline/mascot-dragon.png";
import mascotPhoenix from "assets/images/course/course-offline/mascot-phoenix.png";
import mascotUnicorn from "assets/images/course/course-offline/mascot-unicorn.png";

import { arrayCourseOffline, courseOfflineForm } from "./const";
import FormatNumberic from "share/formatNumber";
import { Link } from "react-router-dom";

import { isMobile } from "react-device-detect";
import { Box, Modal } from "@mui/material";
import RegisterForm from "share/registerForm";
import { set } from "lodash";
SwiperCore.use([Scrollbar, Navigation]);

function CourseOffline() {
  useSiteTitle("offline_page");
  const [openModal, setOpenModal] = useState(false);
  const [course, setCourse] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleRegisterCombo = (course) => {
    let combo = courseOfflineForm.find((e) => e.course === course);
    setCourse(combo);
    setOpenModal(true);
  };
  const renderPopUp = () => {
    const style = {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      // borderRadius: "40px",
      boxShadow: 24,
      position: "absolute",
      display: "block",
      width: isMobile ? "90%" : "50%",
      maxHeight: "80%",
      overflow: isMobile ? "scroll" : "auto",
      "&:focus-visible": {
        outline: "0px solid #1976d2", // Add an outline for keyboard focus
        boxShadow: 12, // Example: different shadow on focus
      },
    };
    return (
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={
          {
            // borderRadius: "40px",
          }
        }
      >
        <Box sx={style}>
          <RegisterForm
            title={"Thông tin đăng ký !"}
            course={course}
            setOpenModal={(i) => {
              setOpenModal(i);
            }}
          />
        </Box>
      </Modal>
    );
  };

  const renderPrice = (price) => {
    return (
      <div className="headline-h2 course-offline-promo-price">
        <FormatNumberic num={price} />
      </div>
    );
  };

  return (
    <div className="courseOffline-page">
      <SoulRetreatsBanner
        bannerImg={banner}
        headText="Khóa học Offline"
        subText="THE ORIGINS có các khóa học trực tiếp nhằm cung cấp trải nghiệm học tốt nhất cho học viên."
      />
      <div
        className="relative"
        style={{ height: "1750px", paddingTop: "50px" }}
      >
        <div
          className="section-background"
          style={{
            backgroundImage: "url(" + background01 + ")",
            backgroundSize: "cover",
          }}
        />
        <div
          className="section-background bg-2"
          style={{
            backgroundImage: "url(" + background02 + ")",
            backgroundSize: "cover",
          }}
        />
        <div className="overlay"></div>
        <div className="relative" style={{ height: "1700px", width: "100%" }}>
          {arrayCourseOffline.map((item, index) => {
            return (
              <div className={`level level${index + 1}`} key={index}>
                <div className="relative level-content">
                  <img src={item.img} alt={item.img} />
                  <div className="box">
                    <p className="eng-text">{item.headEng}</p>
                    {item.headEng2 && (
                      <p className="eng-text">{item.headEng2}</p>
                    )}
                    <p className="mt-2 vie-text">{item.headVie}</p>
                    {item.headVie2 && (
                      <p className="vie-text">{item.headVie2}</p>
                    )}
                    <div className="popup">
                      <div>{item.description}</div>
                      {item.price && (
                        <div className="d-flex align-items-center justify-content-center m-2">
                          {renderPrice(item.price)}
                        </div>
                      )}
                      <Link
                        to={item.url}
                        style={{ fontSize: "24px", fontWeight: "bold" }}
                      >
                        Xem thêm
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="mascot-dragon">
            <img
              src={mascotDragon}
              alt={mascotDragon}
              className="mascot-dragon"
            />
            <div className="popup">
              Combo Dragon: gồm khóa SM, UM, ICPC, ACT 01, IMCPC, GOL, ACT 02
              {renderPrice(198888000)}
              <h4
                onClick={() => {
                  handleRegisterCombo("combo_dragon");
                }}
              >
                Đăng ký Ngay
              </h4>
            </div>
          </div>
          <div className="mascot-phoenix">
            <img src={mascotPhoenix} alt={mascotPhoenix} />
            <div className="popup">
              Combo phoenix: gồm khóa SM, UM, ICPC, ACT 01
              {renderPrice(105888000)}
              <h4
                onClick={() => {
                  handleRegisterCombo("combo_phoenix");
                }}
              >
                Đăng ký Ngay
              </h4>
            </div>
          </div>
          <div className="mascot-unicorn">
            <img src={mascotUnicorn} alt={mascotUnicorn} />
            <div className="popup">
              Combo Unicorn: gồm khóa SM, UM
              {renderPrice(60888000)}
              <h4
                onClick={() => {
                  handleRegisterCombo("combo_unicorn");
                }}
              >
                Đăng ký Ngay
              </h4>
            </div>
          </div>
        </div>
      </div>
      {renderPopUp()}
    </div>
  );
}

export default CourseOffline;
