import React, { useEffect, useState, useRef } from "react";
import PyramidDisplay from "app/modules/ClientModules/courses/courseOnline/components/pyramid";
import { isMobile } from "react-device-detect";
import "./styles.scss";
import { pyramidItemsLayout, comboDataDetails, comboSaleDetails, colorsData, stationItemsLayout } from "./const";
import BestSellerImge from "assets/images/best-seller.png";
import { Box, Button, Modal, IconButton } from "@mui/material";
import RegisterForm from "share/registerForm";
import CloseIcon from "@mui/icons-material/Close";
import { arrayMountain } from "../../../ClientModules/courses/courseOffline/const";
import videoBG from "assets/images/course/course-online/backgroundvideo.jpg";
import Fsp from "assets/images/course/course-online/fsp.jpg";
import FormatNumberic from "share/formatNumber";
import FspTitle from "assets/images/course/course-online/FSPtitle.png";
import FSPTitle from "assets/images/course/course-online/chinhphucfansipan.png";
import FSPVas from "assets/images/course/course-online/VasFSP.png";
import FSPElearning from "assets/images/course/course-online/titleFSP.png";
import GeneralCombo from "assets/images/course/course-online/goithanhvienGeneral.png";
import VIPCombo from "assets/images/course/course-online/goithanhvienvip.png";
import BasicCombo from "assets/images/course/course-online/goicoban.png";

function NewTower() {
    const [data, setData] = useState(pyramidItemsLayout);
    const [indexHover, setIndexHovered] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [step, setStep] = useState(0);
    const [dataReg, setDataReg] = useState({});
    const storedParams = localStorage.getItem("utm");
    const parsedParams = JSON.parse(storedParams);
    const [selectedPack, setSelectedPack] = useState(null);
    const [stationData, setStationData] = useState(stationItemsLayout);
    const [popupVisible, setPopupVisible] = useState([false, false, false]);
    const videoRef = useRef(null);

    const scrollToVideo = () => {
        if (videoRef.current) {
            videoRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    function handlePackClick(pack) {
        setSelectedPack(pack);
        switch (pack.label) {
            case "2":
            case "3":
            case "4":
                console.log("----floor1", pack);
                break;
            case "5":
            case "6":
            case "7":
                console.log("----floor2", pack);
                break;
            case "8":
            case "9":
            case "10":
                console.log("----floor3", pack);
                break;
            default:
                console.log("free video", pack);
        }
        scrollToVideo();
    };
    const renderPrice = (price, color) => {
        return (
            <div className="price-fsp" style={{
                background: `-webkit-linear-gradient(${color}, white)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontSize: '32px'
            }}>
                <FormatNumberic num={price} />
            </div>
        );
    };
    const renderPromoPrice = (price) => {
        return (
            <div className="price-promo-fsp">
                <FormatNumberic num={price} />
            </div>
        );
    };
    const updateColors = (index) => {
        const updatedStationData = stationData.map((station, i) => {
            let updatedColor = null;
            switch (index) {
                case 0:
                    updatedColor = "#FFFF2E";
                    setPopupVisible([true, true, true]);
                    break;
                case 1:
                    if (i === 0) {
                        updatedColor = "#FFFF2E";
                        setPopupVisible([true, false, false]);
                    }
                    break;
                case 2:
                    if (i === 1) {
                        updatedColor = "#FFFF2E";
                        setPopupVisible([false, true, false]);
                    }
                    break;
                case 3:
                    if (i === 2) {
                        updatedColor = "#FFFF2E";
                        setPopupVisible([false, false, true]);
                    }
                    break;
                default:
                    break;
            }

            return { ...station, color: updatedColor };
        });

        const updatedData = data.map((floor, floorIndex) =>
            floor.map(pack => {
                let newColor = pack.color;
                switch (index) {
                    case 0:
                        if (pack.label !== "1") {
                            newColor = "#FFFF2E";
                            setPopupVisible([true, true, true]);
                        }
                        break;
                    case 1:
                        if (floorIndex === 3 && pack.label !== "1") {
                            newColor = "#FFFF2E";
                            setPopupVisible([true, false, false]);
                        }
                        break;
                    case 2:
                        if (floorIndex === 2) {
                            newColor = "#FFFF2E";
                            setPopupVisible([false, true, false]);
                        }
                        break;
                    case 3:
                        if (floorIndex === 0 || floorIndex === 1) {
                            newColor = "#FFFF2E";
                            setPopupVisible([false, false, true]);
                        }
                        break;
                    default:
                        break;
                }

                return { ...pack, color: newColor };
            })
        );

        setStationData(updatedStationData);
        setData(index === null ? pyramidItemsLayout : updatedData);
    };
    const renderSwitch = () => {
        switch (step) {
            case 0:
                return (
                    <RegisterForm
                        title={"Thông tin đăng ký !"}
                        course={arrayMountain[0].mountainTag}
                        mountain={"FSP"}
                        setStep={(i) => {
                            setStep(i);
                        }}
                        setOpenModal={(i) => {
                            setOpenModal(i);
                        }}
                        setDataReg={(i) => {
                            setDataReg(i);
                        }}
                        parsedParams={parsedParams}
                    />
                );
            case 1:
                return (
                    <div className="popup">
                        <div className="popup-background"></div>
                        <div className="overlay" />
                        <div className="col-12 position-relative">
                            <div className="px-5 py-5">
                                <div className="row">
                                    <h2>CHÚC MỪNG BẠN </h2>
                                    <h4 style={{ textAlign: "center" }}>
                                        ĐÃ ĐĂNG KÝ KHÓA HỌC ONLINE
                                    </h4>
                                    <h4 style={{ textAlign: "center" }}>
                                        E-LEARNING SKILLSET
                                    </h4>
                                </div>
                                <div className="row mt-4 text-center">
                                    <p> Hành trình phát triển kỹ năng của bạn chính thức bắt đầu</p>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return <></>;
        }
    };
    const renderPopUp = () => {
        const style = {
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            position: "absolute",
            display: "block",
            width: isMobile ? "90%" : "50%",
            maxHeight: "80%",
            overflow: isMobile ? "scroll" : "auto",
            "&:focus-visible": {
                outline: "0px solid #1976d2",
                boxShadow: 12,
            },
        };
        return (
            <Modal
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                    setStep(0);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={
                    {
                        // borderRadius: "40px",
                    }
                }
            >
                <Box sx={style}>
                    <IconButton
                        onClick={() => {
                            setOpenModal(false);
                            setStep(0);
                        }}
                        sx={{ position: "absolute", top: 10, right: 10 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {renderSwitch()}
                </Box>
            </Modal>
        );
    };
    const renderVideoContainer = () => {
        return (
            <div className="row screen-section-free"
                style={{
                    backgroundImage: `url(${videoBG})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}
                ref={videoRef}>
                <div className="content-container-free">
                    <h1>BẠN ĐÃ ĐƯỢC NHẬN BÀI MIỄN PHÍ ĐẦU TIÊN</h1>
                    <h2 className="centered-text pt-10">Bài 1</h2>
                    <div className="video-container">
                        <iframe
                            src="https://www.youtube.com/embed/mf4upAPwHEo"
                            title="Lukas Graham - Happy For You (feat. Vũ.) Performance Video"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen>
                        </iframe>
                    </div>
                    <h5 className="pt-10">HÃY ĐĂNG KÝ ĐỂ TIẾP TỤC CUỘC HÀNH TRÌNH CHINH PHỤC FANSIPANG</h5>
                </div>
            </div>
        );
    };
    const renderMountainTitle = () => {
        return (
            <div>
                <div className="row screen-split-container pb-10"
                    style={{
                        backgroundImage: `url(${FspTitle})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                    }}>

                    <div class="mountain-title">
                        <img className="coach-vas" src={FSPVas} alt="Coach Vasanth Gopalan" />
                        <img className="fsp" src={FSPTitle} alt="Fansipang" />
                    </div>
                    <div className="col-md-6 screen-section screen-left ">
                        <div className="promo-card">
                            <img style={{ width: '70%', display: 'block', margin: '0 auto' }} src={FSPElearning} alt="Coach Vasanth Gopalan" />
                            <div className="listFSP pt-10">
                                <ul>
                                    <li>4 CHỦ ĐỀ | 10 VIDEO | 70 PHÚT</li>
                                    <li>Học cùng chuyên gia Huấn Luyện quốc tế Vasanth Gopalan</li>
                                    <li>Tích lũy điểm qua các video để được các ưu đãi khóa học khác</li>
                                    <li>Cạnh tranh các học viên khác để có cơ hội leo Fansipan miễn phí cùng Coach Vasanth Gopalan</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 screen-section screen-right">
                        <div className="content-container">
                            <h2 className="mountain-title-text">Lý Do Để Chinh Phục Fansipang Bằng E-learning</h2>
                            <div className="video-container">
                                <iframe
                                    src="https://www.youtube.com/embed/mf4upAPwHEo"
                                    title="Lukas Graham - Happy For You (feat. Vũ.) Performance Video"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen>
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const renderFSPPyramid = () => {
        return (
            <div className="row background py-20" style={{
                backgroundImage: ` url(${Fsp})`,
            }}>
                <div className="col-md-5">
                    <PyramidDisplay data={data} onPackClick={handlePackClick} stationData={stationData} popupVisible={popupVisible} />
                </div>
                <div className="col-md-7">
                    <div className="color-box-row">
                        {colorsData.map((item, index) => (
                            <div className="color-box-wrapper" key={index}>
                                <div className="color-box-item" style={{ backgroundColor: item.color }}>
                                </div>
                                <p className="description">{item.text}</p>
                            </div>
                        ))}
                    </div>
                    <div className="container mt-5">
                        <div className="row">
                            {comboDataDetails.map((card, index) => (
                                <div className="col-md-3" key={index}>
                                    <div className="container">
                                        <div
                                            className="shaking-card d-flex h-100 justify-content-center"
                                            onMouseEnter={() => {
                                                setIndexHovered(index);
                                                updateColors(index);
                                            }}
                                            onMouseLeave={() => {
                                                updateColors(null);
                                                setPopupVisible([false, false, false]);
                                            }}
                                            style={{ fontWeight: 'bolder' }}
                                        >
                                            <p>{card.defaultText}</p>
                                        </div>

                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="row pt-10">
                            {comboSaleDetails
                                .filter(card => card.keyWord.includes(indexHover))
                                .map((detailCard, detailIndex) => (
                                    <div className="col" key={detailIndex}>
                                        <div
                                            className={`shaking-card-detail d-flex h-100 flex-column ${detailCard.isBestSeller ? 'best-seller-border' : ''}`}
                                            onMouseEnter={() => {
                                                (detailIndex === 0) ? updateColors(indexHover) : updateColors(0)
                                            }}
                                            onMouseLeave={() => {
                                                updateColors(null);
                                                setPopupVisible([false, false, false]);
                                            }}
                                            onClick={() => {
                                                setOpenModal(true);
                                            }}
                                        >
                                            {detailCard.isBestSeller && (
                                                <div className="best-seller-badge">
                                                    <img src={BestSellerImge} alt="Best Seller" />
                                                </div>
                                            )}
                                            {/* <h4 className="membership">{detailCard.comboName}</h4> */}

                                            <img
                                                src={
                                                    (() => {
                                                        switch (detailIndex) {
                                                            case 0:
                                                                return BasicCombo; // Replace with actual image paths
                                                            case 1:
                                                                return GeneralCombo;
                                                            case 2:
                                                                return VIPCombo;
                                                            default:
                                                                return BestSellerImge; // Fallback image
                                                        }
                                                    })()
                                                }
                                                alt="Combo"
                                            />
                                            <ul className="custom-list">
                                                {detailCard.detail.map((item, i) => (
                                                    <li key={i}>{item}</li>
                                                ))}
                                                {detailCard.prerequisite && (
                                                    <li key="prerequisite" style={{ color: 'red' }}>
                                                        {detailCard.prerequisite}
                                                    </li>
                                                )}
                                            </ul>
                                            <div>
                                                {renderPromoPrice(detailCard.promoprice)}
                                            </div>
                                            <div>
                                                {renderPrice(detailCard.price, detailCard.colorPromo)}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>

        )
    };
    return (
        <div>
            {renderMountainTitle()}
            {renderFSPPyramid()}
            {renderVideoContainer()}
            {renderPopUp()}
            <div className="tfs-fixed-bottom">
                <div className="container d-flex align-items-center justify-content-center h-100">
                    <div className="img-register">
                        <Button
                            className=" tfs-btn-register animate-ring"
                            onClick={() => {
                                setOpenModal(true);
                            }}
                        >
                            Đăng ký
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewTower;
