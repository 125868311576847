import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import BigTitle from "assets/images/course/course-online/titleFSP.png";
import BG from "assets/images/course/course-online/4mounbackground .jpg";
import VasSki from "assets/images/course/course-online/vasngau.png";
import "./styles.scss";
gsap.registerPlugin(ScrollTrigger);

const ScrollParallax = () => {
    const containerRef = useRef(null);

    useEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.scrollDist',
                start: '0 0',
                end: '100% 100%',
                scrub: 2.5,
            },
        });

        tl.fromTo('.sky', { y: 0 }, { y: -200 }, 0)
            .fromTo('.cloud1', { y: 100 }, { y: -800 }, 0)
            .fromTo('.cloud2', { y: -150 }, { y: -500 }, 0)
            .fromTo('.cloud3', { y: -50 }, { y: -650 }, 0)
            .fromTo('.mountBg', { y: -10 }, { y: -100 }, 0)
            .fromTo('.mountMg', { y: -30 }, { y: -250 }, 0)
            .fromTo('.mountFg', { y: -50 }, { y: -600 }, 0)
        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        }
    }, []);

    return (
        <div>
            <div className="scrollDist"></div>
            <main ref={containerRef}>
                <svg viewBox="0 0 1200 680" xmlns="http://www.w3.org/2000/svg">
                    <mask id="m">
                        <g className="cloud1">
                            <rect fill="#fff" width="100%" height="801" y="799" />
                            <image xlinkHref="https://assets.codepen.io/721952/cloud1Mask.jpg" width="1200" height="800" />
                        </g>
                    </mask>
                    <image className="sky" xlinkHref="https://assets.codepen.io/721952/sky.jpg" width="1200" height="590" />
                    <image className="mountBg" xlinkHref="https://assets.codepen.io/721952/mountBg.png" width="1200" height="800" />
                    <image className="mountMg" xlinkHref="https://assets.codepen.io/721952/mountMg.png" width="1200" height="800" />
                    <image className="cloud2" xlinkHref="https://assets.codepen.io/721952/cloud2.png" width="1200" height="800" />
                    <image className="mountFg" xlinkHref="https://assets.codepen.io/721952/mountFg.png" width="1200" height="800" />
                    <image className="cloud1" xlinkHref="https://assets.codepen.io/721952/cloud1.png" width="1200" height="800" />
                    <image className="cloud3" xlinkHref="https://assets.codepen.io/721952/cloud3.png" width="1200" height="800" />
                    <text fill="#fff" x="480" y="230">Lăn Xuống Để Bắt Đầu Hành Trình</text>
                    <polyline className="arrow" fill="#fff" points="599,250 599,289 590,279 590,282 600,292 610,282 610,279 601,289 601,250" />
                    <g mask="url(#m)">
                        <rect fill="#fff" width="100%" height="100%" />
                        <image href={BG} x="0" y="10" width="100%" height="100%" />
                        <image className="parallax-title" href={BigTitle} x="500" y="250" />
                        <image href={VasSki} x="00" y="200" width="600" />
                    </g>
                </svg>
            </main>
        </div>
    );
};

export default ScrollParallax;
