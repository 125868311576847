export const colorsData = [
    { color: '#0000FF', text: 'Khóa đã học/miễn phí' },
    { color: '#FF0000', text: 'Khóa đang chọn' },
    { color: '#FFFF2E', text: 'Khóa theo gói' },
];
export const pyramidItemsLayout = [
    [{ label: '10', color: '#808080' }],
    [{ label: '8', color: '#808080' }, { label: '9', color: '#808080' }],
    [{ label: '7', color: '#808080' }, { label: '6', color: '#808080' }, { label: '5', color: '#808080' }],
    [{ label: '1', color: '#0000FF' }, { label: '2', color: '#808080' }, { label: '3', color: '#808080' }, { label: '4', color: '#808080' }]
];

export const comboDataDetails = [
    { defaultText: "Full trạm" },
    { defaultText: "Trạm 1: Kỹ Năng" },
    { defaultText: "Trạm 2: Tư Duy" },
    { defaultText: "Trạm 3: Về Đích" },
];

export const comboSaleDetails = [
    { comboName: 'GÓI TIÊU CHUẨN', colorPromo: '#FFFFFF', keyWord: [0], promoprice: 1999000, price: 999000, detail: ['Mở Khóa Tất Cả Video', 'Mở Khóa Tất Cả Các Trạm'] },
    { comboName: 'GÓI KỸ NĂNG', colorPromo: '#FFFFFF', keyWord: [1], promoprice: 890000, price: 499000, detail: ['Mở Khóa Video 2 3 4', 'Mở Khóa Trạm Kỹ Năng', 'Nhận MIỄN PHÍ vé GENERAL tham gia THE RESTART'] },
    { comboName: 'GÓI TƯ DUY', colorPromo: '#FFFFFF', keyWord: [2], promoprice: 890000, price: 599000, detail: ['Mở Khóa Video 5 6 7', 'Mở Khóa Trạm Tư Duy'], prerequisite: 'Phải hoàn thành tầng dưới' },
    { comboName: 'GÓI VỀ ĐÍCH', colorPromo: '#FFFFFF', keyWord: [3], promoprice: 890000, price: 599000, detail: ['Mở Khóa Video 8 9 10', 'Mở Khóa Trạm Về Đích'], prerequisite: 'Phải hoàn thành các tầng dưới' },
    { comboName: 'GÓI THÀNH VIÊN GENERAL', colorPromo: '#FF0000', keyWord: [0, 1, 2, 3], promoprice: 2499000, price: 890000, detail: ['Mở Khóa Tất Cả Video', 'Mở Khóa Tất Cả Các Trạm', 'Nhận MIỄN PHÍ vé GENERAL tham gia THE RESTART'], isBestSeller: true },
    { comboName: 'GÓI THÀNH VIÊN VIP', colorPromo: '#FFFFFF', keyWord: [0, 1, 2, 3], promoprice: 7999000, price: 4399000, detail: ['Mở Khóa Tất Cả Video', 'Mở Khóa Tất Cả Các Trạm', 'Nhận MIỄN PHÍ vé VIP tham gia THE RESTART'] },
]

export const stationItemsLayout = [
    {
        stationNameEng: 'Skill Station',
        stationNameVie: 'Trạm Kĩ Năng',
        price: 300000,
        testDetail: 'thông tin test',
        color: '#808080'
    },
    {
        stationNameEng: 'Mindset Station',
        stationNameVie: 'Trạm Tư Duy',
        price: 300000,
        testDetail: 'thông tin test',
        color: '#808080'
    },
    {
        stationNameEng: 'Finish Station',
        stationNameVie: 'Trạm Về Đích',
        price: 300000,
        testDetail: 'thông tin test',
        color: '#808080'
    },
]